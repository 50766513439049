.slider ul, .slider li.panel { margin: 0; padding: 0; list-style: none; position: relative; display: block; }
.slider, .slider li.panel { overflow: hidden; background: none; }
.slider li.panel:not(.slide):not(:first-child) { display: none; }

.slider-nav { margin-top: 1em; display: block; text-align: center; width: 100%; }
.slider-nav ol { margin: 0 0 0 -10px; }
.slider-nav li { margin: 0 0 0 10px; padding: 0; background: none; display: inline-block; font-size: 0; }
.slider-nav li:before { content: none; }
.slider-nav li a { width: 8px; height: 8px; display: block; background: #2f4e75; text-indent: -80000px; border-radius: 50%; -moz-border-radius: 50%; -webkit-border-radius: 50%; }
.slider-nav li.current a { background: #b8bdc5; }

.slider-nav .prev { position: absolute; left: 1em; top: 50%; display: inline-block; width: 2em; height: 2em; border-left: 0.2em solid #87cff6; border-bottom: 0.2em solid #87cff6; transform: rotate(45deg); margin-right: 0.5em; display: block; }
.slider-nav .prev:hover { border-color: #e2001a; }
.slider-nav .next { position: absolute; right: 1em; top: 50%; display: inline-block; width: 2em; height: 2em; border-right: 0.2em solid #87cff6; border-top: 0.2em solid #87cff6; transform: rotate(45deg); margin-right: 0.5em; display: block; }
.slider-nav .next:hover { border-color: #e2001a; }

/*.slider-nav .prev { position: absolute; left: -3em; top: 50%; display: inline-block; width: 2em; height: 2em; transform: translateY(-50%); }
.slider-nav .prev:after { content: ""; position: absolute; right: 0.75em; top: 50%; display: block; width: 1em; height: 1em; border-left: 0.2em solid $black; border-bottom: 0.2em solid $black; transform: rotate(45deg) translate3d(0,0,1px); transform-origin: 50% 50%; z-index: 10; margin-top: -0.4em; }
.slider-nav .prev:hover { border-color: $black; }
.slider-nav .next { position: absolute; right: -3em; top: 50%; display: inline-block; width: 2em; height: 2em; transform: translateY(-50%); }
.slider-nav .next:after { content: ""; position: absolute; left: 0.75em; top: 50%; display: block; width: 1em; height: 1em; border-right: 0.2em solid $black; border-top: 0.2em solid $black; transform: rotate(45deg) translate3d(0,0,1px); transform-origin: 50% 50%; z-index: 10; margin-top: -0.4em; }
.slider-nav .next:hover { border-color: $black; }*/

/* Case studies slider */
.cases-slider { margin-left: -1em; margin-right: -1em; }
.cases-slider .item { margin: 1em 1em 1.5em 1em; }

.cases-slider-nav { display: flex; margin-top: 1.75em; }
.cases-slider-btn { padding: 0.6em 0.8em 0.4em 0.8em; }
.cases-slider-btn .icon { width: 2em; height: 2em; }
.cases-slider-prev { border-radius: 10px 0 0 10px; border-right: 1px solid $middleGreen; }
.cases-slider-next { border-radius: 0 10px 10px 0; margin-right: 1em; }