.header { height: 7em; padding: 0; position: absolute; left: 0; top: 0; width: 100%; text-align: center; z-index: 1000; }
.header-logo { display: inline-block; width: 205px; margin-top: 45px; position: relative; z-index: 1005; }
.header-logo-img { display: inline-block; width: 205px; }

.header-contact { position: absolute; right: 2rem; top: 1.75rem; z-index: 1000; }
.header-contact.btn { padding: 0.5em 1em 0.6em 0.75em; color: $gray; }
.header-contact.btn:hover, .header-contact.btn:active { color: $white; }

/* Main navigation */

.main-nav { margin: 0; position: fixed; min-width: 26rem; left: 0; top: 0; transform: translateX(-100%); transition: transform 0.2s ease-out; background: $green; z-index: 1050; padding: 6.5em 3em 3em 3em; height: 100%; text-align: left; }
.main-nav ul { margin: 0; }
.main-nav ul li { padding: 0; margin: 0; background: none; }
.main-nav ul li a, .main-nav-item { color: $white; font-size: 2em; line-height: 1.1; font-weight: 600; display: block; text-decoration: none; padding: 0.3em 0; }
.main-nav ul li a span { display: inline-block; position: relative; }
.main-nav ul li a span:after { content: ""; display: block; width: 100%; height: 2px; background: $darkGreen; transform: scaleX(0); transition: none; position: absolute; left: 0; bottom: 0; }
.main-nav ul li a:hover, .main-nav ul li a:active { color: $darkGreen; }
.main-nav ul li a:hover span:after, .main-nav ul li a:active span:after { transform: scaleX(1); transition: transform 0.1s; }
.main-nav ul li.active a { color: $black; }

.menu-open .main-nav { transform: translateX(0); transition: transform 0.2s ease-in; }

.main-nav .sub-nav li a { font-size: 1.2em; line-height: 1.3; padding: 0.6em 0 0.6em 1.5em; position: relative; }
.main-nav .sub-nav li a .icon-chevron-right { position: absolute; left: 0; top: 0.6em; width: 1.4em; height: 1.4em; margin-right: -5px; }
.main-nav .sub-nav li a.alt { background: $lightGreen; padding: 0.2em 0.75em 0.35em 0.75em; border-radius: 1em; width: auto; display: inline-block; margin: 0.25em 0 0.75em 0.25em; }
.main-nav .sub-nav li a.alt { &:hover, &:active { background: $middleGreen; color: $white; } }
.main-nav .sub-nav li a.alt { &:hover span:after, &:active span:after { display: none; } }

.menu-trigger { position: absolute; left: 2rem; top: 1.75rem; z-index: 1100; font-size: 1em; font-weight: 600; padding: 0.3em 0.75em 0.4em 0.75em; color: $green; background: $white; box-shadow: 0px 6px 24px 0px rgba($black, 0.11); border-radius: 10px; text-transform: uppercase; text-decoration: none; display: flex; align-items: center; transition: all 0.1s; }
.menu-trigger .icon { fill: $green; width: 2.3rem; height: 2.3rem; display: block; margin-right: 0.3em; transition: fill 0.1s; }
.menu-trigger:hover, .menu-trigger:active { background: $middleGreen; color: $white; }
.menu-trigger .icon-close { display: none; }
.menu-open .menu-trigger { position: fixed; }
.menu-open .icon-menu { display: none; }
.menu-open .icon-close { display: block; }

.login { margin: 0; position: absolute; right: 0; top: 0; }
.login ul { margin: 0; }
.login ul li { margin: 0; padding: 0 0.5em; display: inline-block; }
.login ul li a:not(.btn) { color: $white; font-size: 0.9em; display: block; line-height: 5.55em; white-space: nowrap; }
.login ul li a.btn { vertical-align: middle; }

@media screen and (max-width: $xl) {
	.main-nav .sub-nav li a .icon-chevron-right { top: 0.5em; }
}

@media screen and (max-width: $md) {
	.main-nav { min-width: 24rem; }
	.main-nav ul li a { font-size: 1.7em; padding: 0.5em 0; }
}

@media screen and (max-width: $sm) {
	.header-contact { display: none; }
	.header-logo { position: absolute; right: 2.25rem; top: -0.5em; width: 160px; }
}

@media screen and (max-width: $xs) {
	.main-nav { min-width: 0; width: 100vw; }
	.menu-trigger { left: 1em; }
}

@media screen and (max-width: $xxs) {
	.main-nav { padding: 6.5em 2em 2em 2em;  }

	.header-logo { width: 140px; top: -0.4em; right: 2rem; }
}