.footer { background: $gray; color: #d4d4d4; position: relative; z-index: 5; }

.footer-nav { padding: 1.25em 0; border-bottom: 1px solid #b3b3b3; }
.footer-nav ul { display: flex; flex-wrap: wrap; justify-content: space-between; margin-bottom: 0; }
.footer-nav ul li { margin-bottom: 0; padding: 0; }
.footer-nav ul li:not(:last-child) { padding-right: 0.75em; }
.footer-nav ul li a { color: $white; font-size: 0.9em; text-transform: uppercase; }

.footer-content { padding: 1rem 1rem 1rem 1rem; font-size: 0.9em; line-height: 1.3; }

.porta { width: 69px; height: 15px; margin-top: 0.25em; display: block; float: right; color: #e4e4e4; fill: #e4e4e4; }
.porta:hover { color: $white; fill: $white; }

@media screen and (max-width: $lg) {
	.footer-nav ul { justify-content: flex-start; }
}

@media screen and (max-width: $sm) {
	.footer-nav ul li { width: 100%; }
}
