.content { padding: 0; position: relative; padding-top: 7em; overflow: hidden; }
.content:before { content: ""; display: block; width: 435px; height: 842px; position: absolute; right: 0; top: 0; background: url('../img/ellipse-top.svg'); z-index: 2; }

/* Block */

.block { padding: 2.5em 0; }
.block.block-narrow { padding: 1.5em 0; }
.container, .container-fluid { position: relative; z-index: 4; }

.block.block-alt { background: $gray; color: $white; }
.block.block-alt a { color: $white; }

.block.block-alpha { padding-top: 0; }
.block.block-omega { padding-bottom: 0; }

.block-bg { background: $lightBgGray; }

.block-bg-ellipse { position: relative; background: $lightBgGray; }
.block-bg-ellipse:after { content: ""; display: block; width: 100vw; min-width: 1890px; height: 985px; position: absolute; left: 50%; top: -22em; background: url('../img/ellipse-block.svg') 0 0 no-repeat; background-size: 100% auto; transform: translateX(-50%); z-index: 1; }
.block-bg-ellipse-hp:after { top: -33em; }
.block-bg-ellipse-cost:after { top: -30em; }
.block-bg-ellipse-tips:after { top: -19em; }
.block-bg-ellipse-refs:after { top: -11.5em; }
.block-bg-ellipse-ref:after { top: -25.5em; }

.section-head { text-align: center; }
.section-intro { max-width: 500px; margin-left: auto; margin-right: auto; text-align: center; }

.narrow-col-700 { max-width: 700px; margin-left: auto; margin-right: auto; }
.narrow-col-500 { max-width: 500px; margin-left: auto; margin-right: auto; }

.item-box { @include boxShadow; border-radius: 10px; background: $white; overflow: hidden; }
.item-box-round { border-radius: 1000px; }
.item-box-round .item-inner, .item-box-round.item-inner { padding: 1.15em 1.25em; }
.item-border { position: relative; }
.item-border:after { content: ""; display: block; width: 4px; height: 100%; background: $green; position: absolute; left: 0; top: 0; }
.item-inner { padding: 1.5em 2em; position: relative; }
.item-title { color: $green; font-size: 1.6em; font-weight: 600; margin-bottom: 0.6em; }
.item-title a { color: $green; text-decoration: underline; }
.item-title a:hover, .item-title a:active { text-decoration: none; color: $middleGreen; }
.item-text { font-weight: 500; font-size: 0.9em; }
.item-text { p:last-child, ul:last-child, ol:last-child { margin-bottom: 0; } }

.item-flex { display: flex; justify-content: space-between; }
.item-icon { padding-left: 0.75em; align-self: center; }
.item-icon .icon { width: 8em; height: 8em; fill: $lightGray; }

.item-chevron { position: absolute; right: 0.5em; top: 50%; transform: translateY(-50%); }
.item-chevron .icon { width: 2.5em; height: 2.5em; fill: $lightGray; }
.item-chevron:hover .icon, .item-chevron:active .icon { fill: $middleGreen; }

.item-img-round { overflow: hidden; border-radius: 50%; }

.item-list-joined .item { margin-bottom: 0; }
.item-list-joined .item-box { border-radius: 0; border-bottom: 3px solid lighten($lightGray, 5%); }
.item-list-joined .item:first-of-type .item-box { border-radius: 10px 10px 0 0; }
.item-list-joined .item:last-of-type  .item-box { border-radius: 0 0 10px 10px; margin-bottom: 1.5em; border-bottom: none; }

.box { padding: 1.75em 2.25em; @include boxShadow; border-radius: 5px; margin: 0.5em 0 1.5rem 0; }
.box-title { color: $green; font-size: 0.9em; text-transform: uppercase; font-weight: 600; margin-bottom: 1.5em; }
.box { p, ol, ul, li { &:last-child { margin-bottom: 0; } } }
.box .list-checked li, .box .list-alert li { margin-bottom: 0.8em; }

/* Cta */

.cta { padding: 4.5em 0 4.25em 0; position: relative; background: $lightBgGray; }
.cta-ellipse:before { content: ""; display: block; width: 353px; height: 1164px; position: absolute; left: 0; background: url('../img/ellipse-bottom.svg'); bottom: 1em; z-index: 1; }
.cta-no-bg { background: none; }
.cta-text { @extend .h3; line-height: 1.5; }
.cta-text a { color: $green; }
.cta-text a:hover, .cta-text a:active { color: $middleGreen; }
.cta .text-big { font-size: 2rem; }
.cta .text-big a { text-transform: uppercase; }

/* Homepage */

.perex-hp { font-size: 1.4em; line-height: 1.3; }
.perex-hp h1 { font-size: 2.2rem; }
.perex-image { width: 23em; height: 23em; border-radius: 50%; overflow: hidden; transform: translateX(100px); margin-top: -40px; box-shadow: 0px 16px 24px 0px rgba($black, 0.11); }

.services .item { width: 50%; }
.services .item-double { width: 100%; }

.reasons .item-inner { padding: 0.5em 1em; }
.reasons .item-icon { margin-bottom: 1.25em; padding-left: 0; text-align: center; }
.reasons .item-icon .icon { fill: $green; width: 85px; height: 85px; }
.reasons .item-icon-label { fill: $white; font-family: $baseFont; }
.reasons .icon-starburst .item-icon-label { font-size: 1.5em; }
.reasons .icon-cake .item-icon-label { font-size: 1em; }

.cases .item { margin-bottom: 2.25em; min-height: 17em; }
.cases .item-list .item { width: 100%; }
.cases .item-img { width: 15.5em; height: 15.5em; flex: 0 0 15.5em; }
.cases .item-inner { align-items: center; }
.cases .item-list .item-inner { width: 100%; }
.cases .item-content { padding: 1em 5em 1em 2.5em; }
.case-label { font-size: 0.9em; color: $gray; text-transform: uppercase; margin-bottom: 0.35em; }
.case-link { color: $gray; font-size: 0.9em; }
.case-link .icon { width: 1.9em; height: 1.9em; margin-right: 3px; }

/* Cost management */

.question { list-style: none; position: relative; padding-left: 5rem; min-height: 4rem; margin-bottom: 2rem; font-size: 0.9em; }
.question:before { content: "?"; display: block; width: 3.5rem; height: 3.5rem; background: $white; color: $green; font-size: 1.8rem; @include boxShadow; border-radius: 50%; text-align: center; padding: 0.15em; position: absolute; left: 0; top: 0; }

.service-list .item-inner { padding: 2.25em 4em 2.25em 2em; }
.service-list .item-img { margin-right: 3em; flex: 0 0 290px; }

.steps { display: flex; justify-content: space-between; align-items: center; flex-direction: row; margin: 3.25em -1em 1.5em -1em; }
.step { padding: 0; margin: 0; width: 13em; height: 13em; border-radius: 50%; padding: 3.75em; display: flex; justify-content: center; align-items: center; color: $green; position: relative; }
.step:hover { cursor: pointer; }
.step:before, .step:after { content: ""; display: block; position: absolute; }
.step:before { width: 100%; height: 100%; background: $white; border: 3.5em solid $green; top: 0; left: 0; border-radius: 50%; transition: width, height, border-width 0.1s ease-out; }
.step:after { width: 6em; height: 3.5em; background: url('../img/step-arrow.svg') right center no-repeat; top: 50%; right: -4em; transform: translateY(-50%); }
.step:last-of-type:after { display: none; }
.step .step-label { font-size: 0.9em; text-transform: uppercase; font-weight: 600; z-index: 20; position: relative; }
.step .step-text { visibility: hidden; width: 0; height: 0; display: inline-block; font-size: 0.9em; text-transform: none; color: $textGray; z-index: 100; position: relative; }

.step.step-active { padding: 0; }
.step.step-active .step-label { display: none; }

.step.step-active:before { border-width: 8px; width: 120%; height: 120%; left: -10%; top: -10%; background: $white; z-index: 50 !important; }
.step.step-active .step-text { visibility: visible; width: auto; height: auto; position: relative; }

@for $i from 0 through 5 {
	.step:nth-child(#{$i}):before { z-index: #{10-($i*2)}; }
	.step:nth-child(#{$i}):after { z-index: #{9-($i*2)}; }
}

/* Tips */

.tip { position: relative; padding-left: 5rem; min-height: 4rem; margin-bottom: 1.8rem; font-size: 0.9em; }
.tip:before { content: counter(counter); display: block; width: 3.5rem; height: 3.5rem; background: $white; color: $green; font-size: 1.8rem; line-height: 1.5; @include boxShadow; border-radius: 50%; text-align: center; padding: 0.15em; position: absolute; left: 0; top: 0; }
.tip-head { font-size: 1.2rem; color: $green; margin-bottom: 0.75em; font-weight: 600; }

/* Case study detail */

.article-img-wrap { display: flex; align-items: flex-end; position: relative; }
.article-img { background: $green; max-height: 32em; border-radius: 5px; overflow: hidden; position: relative; }
.article-img:after { content: ""; display: block; width: 100%; height: 100%; background: linear-gradient(to bottom, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 20%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0.7) 100%); position: absolute; left: 0; top: 0; }
.article-img-wrap .article-head-wrap { position: absolute; z-index: 20; padding-bottom: 4.35em; }
.article-head { font-size: 2.6em; line-height: 1.4; text-transform: none; }
.article-img-wrap .article-head { color: $white; }
.article-label { background: $white; display: inline-block; margin-bottom: 1.5em; text-align: left; min-width: 11.5rem; font-weight: 600; font-size: 0.9em; color: $gray; }
.article-img-wrap .article-label { position: absolute; right: 2em; top: 2.25em; border-radius: 5px; padding: 1.35em 1.5em; @include boxShadow; }
.article-label-cost { color: $green; font-size: 1.8rem; line-height: 1.3; display: block; }

.article-content { padding: 2.5em 3.5em 0 3.5em; transform: translateY(-3.5em); position: relative; }
.article-content:before { content: ""; display: block; width: 100%; height: 3.5em; background: $white; position: absolute; left: 0; top: 0; }
.article-content .entry { position: relative; z-index: 10; }
.article-content .entry { h2, h3, h4, h5, h6 { color: $green; } }

blockquote { padding-left: 3rem; position: relative; }
blockquote:before { content: ""; display: block; width: 3px; height: calc(100% - 0.8em); background: $green; position: absolute; left: 1.2rem; top: 0.4em; }
blockquote p { position: relative; font-size: 1.2rem !important; }
blockquote p:before, blockquote p:after { content: '"'; color: $green; font-size: 1.3rem; line-height: 1; }
blockquote p:before { margin-right: 0.2rem; }
blockquote p:after { margin-left: 0.2rem; }

/* Contact */

.person { margin-bottom: 1.5em; }
.person-img { width: 7.5em; height: 7.5em; border-radius: 50%; overflow: hidden; margin-bottom: 0.8em; }
.person-info { margin-bottom: 0.75em; }
.person-name { font-size: 1.8em; color: $green; font-weight: 600; margin-bottom: 0; line-height: 1.5; }
.person-position { font-size: 0.9em; color: $gray; text-transform: uppercase; }
.person-contacts { font-size: 1.2em; color: $baseFontColor; }
.person-contacts p { margin-bottom: 0; }
.person-contacts a { color: $baseFontColor; }

.box-contact { display: flex; padding: 0; overflow: hidden; margin-bottom: 0.75em; }
.box-contact-inner { padding: 1.75rem 2.25rem; width: 50%; font-size: 1.2em; line-height: 1.5; }
.box-contact-small { font-size: 0.9rem; }
.box-contact-map { width: 50%; background: #95e3cd; }

/* Gmap */

.map { width: 100%; height: 100%; }

.popup-contact { background: $white; color: $black; width: 190px; height: 80px; padding: 0.75em; }
.popup-contact ul { margin: 0; }
.popup-contact ul li { padding: 0; background: none; }

/* Pager */

.pager { margin: 1.5em 0; text-align: center; position: relative; }
.pager li { display: inline-block; padding: 0; background: none; }
.pager li a { display: block; width: 36px; height: 36px; line-height: 36px; background: $gray; text-align: center; }
.pager li a.active { background: $gray; color: $white; text-decoration: none; }
.pager .prev { position: absolute; left: 0; top: 0; }
.pager .next { position: absolute; right: 0; top: 0; }
.pager .counter { display: none; }

@media screen and (max-width: 1700px) {
	.content:before { transform: scale(0.9); transform-origin: (435px 0); }
	.cta-ellipse:before { transform: scale(0.9); transform-origin: (0 1164px); }

	.perex-image { transform: translateX(80px); }
}

@media screen and (max-width: $xl) {
	.content:before { transform: scale(0.8); top: -4em; right: -1em; }

	.block { padding: 2.25em 0; }

	.perex-hp { font-size: 1.8em; }
	.perex-image { width: 20em; height: 20em; transform: translateX(50px); }

	.cta { padding: 4em 0 3.75em 0; }
	.cta-text-big { font-size: 1.8rem; }
	.cta-ellipse:before { transform: scale(0.8); bottom: -1em; }

	.item-title { font-size: 1.5em; }

	.step:after { right: -5em; }
}

@media screen and (max-width: $lg) {
	.perex-image { transform: translateX(0); }

	.step:after { right: -3em; }
}

@media screen and (max-width: $md) {
	.cta-ellipse:before { display: none; }

	.step { width: 9.25em; height: 9.25em; transform-origin: 0 0; padding: 3em; }
	.step:before { border-width: 2em; }
	.step:after { width: 4em; height: 2.5em; right: -2em; }
	.step span { font-size: 0.8em; }

	.cost-management .item-flex { flex-wrap: wrap; }
	.cost-management .item-img { margin-bottom: 1.25em; }

	.content:before { display: none; }

	.services .item { width: 100%; }
	.reasons .item { width: 100% !important; }

	.cases .item-list .item { margin-left: auto; margin-right: auto; }
	.cases .item-flex { flex-wrap: wrap; }
	.cases .item-inner { padding: 2em; }
	.cases .item-img { height: auto; border-radius: 0; }
	.cases .item-img img { margin-bottom: 1.5em; }
	.cases .item-box-round { border-radius: 10px; }
	.cases .item-content { padding: 0; }
	.cases-slider-nav { align-items: flex-start; flex-wrap: wrap; }
	.cases-slider-btn { margin-bottom: 1em; }

	.service-list .item-flex { flex-wrap: wrap; }
	.service-list .item-img  { margin-bottom: 1.5em; }

	.box-contact { flex-wrap: wrap; }
	.box-contact-map, .box-contact-inner { width: 100%; }
	.box-contact-map { height: 300px; }
}

@media screen and (max-width: $sm) {
	.steps { flex-wrap: wrap; justify-content: center; }
	.step { break-after: always; margin-bottom: 1em; }
	.step:after { top: auto; bottom: -1.75em; right: auto; left: 50%; transform: translateX(-50%) rotate(90deg); }

	.content:before { transform: scale(0.6); }
	.cta-ellipse:before { transform: scale(0.6); }

	.services .item-flex { flex-wrap: wrap; }
	.services .item-inner { padding: 2em; }
	.services .item-icon { padding-left: 0; display: block; margin: 1em auto 0 auto; }
}

@media screen and (max-width: $xs) {
	.tip { padding-left: 4em; }
	.tip:before { transform: scale(0.7); top: -0.4em; }
}

@media screen and (max-width: $xxs) {
	
}