$baseFont: 'sofia-pro', sans-serif;
$baseFontSize: 20px;
$baseLineHeight: 1.7;
$baseFontWeight: 500;

$baseFontColor: #777777;
$headingColor: #a3a3a3;

$green: #13c18f;
$lightGreen: #5ad4b1;
$lightGreen2: #95e3cd;
$middleGreen: #099f74;
$darkGreen: #06654a;

$white: #fff;
$black: #1d1d1b;
$gray: #a3a3a3;
$lightGray: #e3e3e3;
$lightBgGray: #f6f6f6;
$textGray: #777777;

$xl: 1370px;
$lg: 1200px;
$md: 992px;
$sm: 768px;
$xs: 576px;
$xxs: 420px;