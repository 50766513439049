.modal-box { display: none; background: $white; border-radius: 10px; overflow: hidden; }
.modal-content { padding: 1.5em; }

/* Reference modal */

.reference-modal-content { display: flex; background: $green; max-width: 1420px; overflow: hidden; position: relative; }
.reference-modal-text { color: $white; padding: 2.5em; min-height: 500px; background: $green; }
.reference-modal-title { color: $white; font-weight: 600; font-size: 1.6em; line-height: 1.3; border-bottom: 1px solid $lightGreen; padding-bottom: 0.75em; margin-bottom: 0.35em; }
.reference-modal-cost { font-size: 0.9em; font-weight: 600; border-bottom: 1px solid $lightGreen; padding-bottom: 1.35em; margin-bottom: 1.25em; }
.reference-modal-cost span { font-size: 1.8rem; display: block; }
.reference-modal-desc { margin-bottom: 1.25em; }
.reference-modal-img { display: flex; align-items: stretch; width: auto; max-width: 60%; }
.reference-modal-img img { width: auto; max-width: none; }
.reference-modal-link, .reference-modal-link a { color: darken($darkGreen, 5%); }

.reference-modal-nav { position: absolute; left: 2em; top: 2.25em; display: flex; }
.reference-modal-nav .btn { padding-top: 0.7em !important; padding-bottom: 0.7em !important; }

.reference-modal-no-img { padding-top: 4em; }
.reference-modal-no-img .reference-modal-nav .btn { background: $white; color: $green; }

@media screen and (max-width: $md) {
	.reference-modal-content { flex-wrap: wrap; }
	.reference-modal-img { max-width: 100%; }
}