a { color: $gray; text-decoration: underline; }
a:hover, a:active { text-decoration: none; color: $gray; }

/* Heading */

h1, .h1 { font-weight: 600; color: $green; font-size: 2.6em; line-height: 1.2; margin: 0 0 0.5em 0; text-transform: uppercase; }
h2, .h2 { font-weight: 600; color: $headingColor; font-size: 2em; line-height: 1.2; margin: 0 0 1em 0; text-transform: uppercase; }
h3, .h3 { font-weight: 600; color: $headingColor; font-size: 1.6em; line-height: 1.4; margin: 0 0 0.75em 0; }
h4, .h4 { font-weight: 600; color: $headingColor; font-size: 1em; line-height: 1.5; margin: 0 0 1em 0; }
h5, .h5 { font-weight: 500; color: $headingColor; font-size: 1em; line-height: 1.5; margin: 0 0 1em 0; }
h6, .h6 { font-weight: 500; color: $headingColor; font-size: 1em; line-height: 1.5; margin: 0 0 1em 0; }

/* Paragraphs */

p { margin: 0 0 1em 0; }
address { font-style: normal; }
cite { font-style: normal; }

/* Lists */

ul, ol { list-style: none;  margin: 0 0 1em 0; }
ul li { margin: 0 0 0.25em 0; padding: 0 0 0 1em; }
ul.list-unstyled li { padding: 0; background: none; }

.list-checked li, .list-alert li { padding-left: 1.75rem; position: relative; }
.list-checked li:before, .list-alert li:before { content: ""; display: block; width: 1.2rem; height: 1.2rem; position: absolute; left: 0; top: 0.2em; }
.list-checked li:before { background: url('../img/check-circle-green.svg') 0 0 no-repeat; background-size: 100% 100%; }
.list-alert li:before { background: url('../img/alert-green.svg') 0 0 no-repeat; background-size: 100% 100%; top: 0.15em; }

ol { counter-reset: counter; margin-left: 0; *margin-left: 54px; }
ol li { margin: 0 0 0.5em 0; padding: 0 0 0 1em; *list-style-type: decimal; counter-increment: counter; position: relative; }
ol li:before { color: $gray; content: counter(counter)'. '; position: absolute; left: 0; top: 0; }

dl { margin: 0 0 1em 0; }
dt { font-weight: bold; }


.text-small { font-size: 0.8em; }
.text-big { font-size: 1.2em; }

.text-light { font-weight: 200; }
.text-bold { font-weight: 600; }
strong { font-weight: 600; }

.text-uppercase { text-transform: uppercase; }
.text-nowrap { white-space: nowrap; }

.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }

.color-gray { color: $gray !important; }
.color-green { color: $green !important; }

.f-l { float: left; }
.f-r { float: right; }

hr { display: block; height: 1px; border: 0; border-top: 1px solid $gray; margin: 1em 0; padding: 0; }

sup,sub { height: 0; line-height: 1; vertical-align: baseline; _vertical-align: bottom; position: relative; }
sup { bottom: 1ex; }
sub { top: .5ex; }

.entry:not(.perex) { font-size: 0.9em; }
.entry p { margin-bottom: 1.25em; }
.perex { font-size: 1.2em; color: $green; line-height: 1.5; font-weight: 600; }
.entry h2 { margin-top: 2.25rem; margin-bottom: 1.15rem; font-size: 1.6rem; }
.entry h3 { margin-top: 1.75rem; }
.entry h2:first-child { margin-top: 0; }
.entry h3:first-child { margin-top: 0; }
.entry ul li { position: relative; }
.entry ul:not(.list-alert):not(.list-checked) li:before { content: ''; position: absolute; left: 3px; top: 12px; width: 4px; height: 4px; display: block; background: #000; }

@media screen and (max-width: $xl) {
	h1, .h1 { font-size: 2.4em; }
	h2, .h2 { font-size: 1.8em; }
	h3, .h3 { font-size: 1.4em; }
}

@media screen and (max-width: $lg) {
	
}

@media screen and (max-width: $md) {
	
}

@media screen and (max-width: $sm) {
	
}

@media screen and (max-width: $xs) {
	
}

@media screen and (max-width: $xxs) {
	
}